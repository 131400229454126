/**
 * @author Sergey Tumarkin https://tumarkin.me
 */
import { 
    Card,
    Button,
    Table, 
    Alert,
    Form,
    InputGroup,
    Stack,
    Container,
    Row,
    Col,
 } from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { Link, useNavigate } from 'react-router-dom';


import "react-datepicker/dist/react-datepicker.css";


export default function Forms ( props ){
    const {authtoken} = props
    
    const [isLoading, setIsLoading] = useState(false);
    const [forms, setForms] = useState([]);
    const [formsCount, setFormsCount] = useState({});
    const [formsOffset, setFormsOffset] = useState(0);
    const [showFormsAddRowsButton, setShowFormsAddRowsButton] = useState(false);


    const [search, setSearch] = useState('');
    
    const today = new Date()
    today.setUTCHours(23, 59, 59, 999)
    
    const prevDate = new Date()
    prevDate.setDate( prevDate.getDate() - 360 )
    prevDate.setHours(0, 0, 0, 0)
    const [startDate, setStartDate] = useState( prevDate.toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState( today.toISOString() );

    
    const [showQR, setShowQR] = useState( false );

    const navigate = useNavigate();

    const getForms = ( offset ) => {
        setFormsOffset(offset+1)
        setIsLoading(true)
        API.Get({ method:'getforms', authtoken, search, startDate, endDate, offset})
        .then(({data}) => {
            if (data.result){
                setForms( offset==0 ? data.forms : [...forms, ...data.forms] );
            }
            setShowFormsAddRowsButton( data.result )
        })
        .finally(()=>setIsLoading(false))
    }

    const getFormsCount = ( ) => {
        API.Get({ method:'getformscount', authtoken})
        .then(({data}) => {
            console.log(data.formscount);
            if (data.result){
                setFormsCount( data.formscount );
            }
        })
    }

    useEffect(() => {
        getFormsCount()
    }, [])

    useEffect(() => {
        getForms(0)
    }, [search, startDate, endDate])
    

    const DateInput = forwardRef(({ value, onClick, label }, ref) => (
        <InputGroup className='float-right'> 
            <InputGroup.Text>{label}</InputGroup.Text>
            <Form.Control 
                ref={ref}
                onClick={onClick}
                type="text" 
                value={value}
                style={{width:120}}
            />
        </InputGroup>
    ));

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const currentDate = new Date();
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
    
        const padZero = (num) => (num < 10 ? '0' + num : num);
        const formattedTime = `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
    
        if (date.toDateString() === currentDate.toDateString()) {
            return 'Сегодня<br>' + formattedTime
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'Вчера<br>' + formattedTime
        } else {
            return `${padZero(date.getDate())}&nbsp;${getMonthName(date.getMonth())}<br>${formattedTime}`
        }
    }
    
    const getMonthName = (month) => {
        const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        return months[month];
    }

    return (
        <>
            <Stack direction="horizontal" gap={3}>
                <div className="p-2">
                    <h1>Приёмки</h1>
                </div>
                { 
                        Object.keys(formsCount).length > 0 &&
                        <>
                            <div className="p-2 ms-auto small text-secondary">
                                <div className="small">
                                    {formsCount.count_today} – за сегодня<br/>
                                    {formsCount.count_month} – за месяц<br/>
                                </div>
                            </div>
                            <div className="p-2 small text-secondary">
                                <div className="small">
                                    {formsCount.count_7days} – за 7 дней<br/> 
                                    {formsCount.count_30days} – за 30 дней
                                </div>
                            </div>
                        </>
                }
            </Stack>
            
            
            {/* {
                showQR ? (
                    <Card className="mb-4" style={{maxWidth: 500}}>
                        <Card.Body>
                            <Card.Text>
                                Новые приёмки проводятся через мобильное приложение
                            </Card.Text>
                        </Card.Body>
                    </Card>

                ) : (
                    <>
                        <Button
                            variant="link"
                            onClick={()=>setShowQR(true)}
                            className='px-0 mx-0'
                        >
                            Как добавить новую приёмку?
                        </Button>
                        <div className="small"></div>
                    </>

                )
            } */}
            
            <Card className="mb-4">
                <Card.Header>
                    <Container className='mx-0 px-0'>
                        <Row>
                            <Col xl={6} md={12}>
                                <InputGroup>
                                    <InputGroup.Text>Поиск</InputGroup.Text>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="#, адрес или заказчик" 
                                        onChange={(e)=> setSearch(e.target.value) }
                                    />
                                </InputGroup>
                            </Col>
                            <Col xl={6} md={12} >
                                <Stack direction="horizontal" gap={3} className='justify-content-end'>
                                    <DatePicker 
                                        selected={startDate} 
                                        dateFormat="YYYY-MM-dd" 
                                        onChange={(date) => setStartDate( date.toISOString().split('T')[0] )} 
                                        customInput={<DateInput label="от"/>}
                                    />
                                    <DatePicker 
                                        selected={endDate} 
                                        dateFormat="YYYY-MM-dd" 
                                        onChange={(date) => {
                                            date.setHours(23, 59, 59 )
                                            setEndDate( date.toISOString() )
                                        }}
                                        customInput={<DateInput label="до"/>}
                                    />
                                </Stack> 
                            </Col>
                            
                        </Row>
                    </Container>
                </Card.Header>
                
                <Card.Body>
                    <div class={isLoading && 'opacity-50'}>
                        {
                            forms.length > 0 ? (
                                <>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th className='d-none d-md-table-cell'>#</th>
                                                <th>Адрес</th>
                                                <th className='d-none d-md-table-cell'>Заказчик</th>
                                                <th>Специалист</th>
                                                <th className='d-none d-md-table-cell'>Недостатки/Преверки</th>
                                                <th className='d-none d-md-table-cell'>Дата создания</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            forms.map( ({id, address, fio, checksCountTotal, failChecksCountTotal, timestamp, apartmentNum, customer}) => (
                                                <tr onClick={(e)=> navigate(`/forms/${id}`) } className=''>
                                                    <td className='d-none d-md-table-cell'><Link to={`/forms/${id}`}>{id}</Link></td>
                                                    <td>{address || 'Без адреса'}{apartmentNum ? ", "+apartmentNum : ""}</td>
                                                    <td className='d-none d-md-table-cell'>{customer}</td>
                                                    <td>
                                                        <div className="d-md-none">{timestamp.replace('T', ' ')}</div>
                                                        {fio}
                                                    </td>
                                                    <td className='d-none d-md-table-cell'>{failChecksCountTotal} / {checksCountTotal}</td>
                                                    <td className='d-none d-md-table-cell' dangerouslySetInnerHTML={{ __html: formatDate(timestamp) }}></td>
                                                </tr>
                                            ) )
                                        }
                                        </tbody>
                                    </Table>

                                    <Button
                                    disabled={!showFormsAddRowsButton}
                                        className='px-5'
                                        onClick={()=>{
                                            getForms( formsOffset )
                                        }}
                                    >
                                        Загрузить еще
                                    </Button>
                                </>
                            ) : (
                                <div>По заданным параметрам приёмок не найдено.</div>
                            )
                        }
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}